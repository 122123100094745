import {
  ErrorState,
  ENQUEUE_ERROR,
  DEQUEUE_ERROR,
  ErrorActionTypes,
} from './types';

const initialState: ErrorState = {
  errors: [],
};

export default (state = initialState, action: ErrorActionTypes): ErrorState => {
  switch (action.type) {
    case ENQUEUE_ERROR: {
      return { ...state, errors: [...state.errors, ...action.errors] };
    }

    case DEQUEUE_ERROR: {
      return { ...state, errors: [...state.errors.slice(1)] };
    }
    default: {
      return state;
    }
  }
};
