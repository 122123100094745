import silentJwtDecode from '../../../lib/silentJwtDecode';

import { User, UserState, USER_UPDATE, LOGOUT, UserActionTypes } from './types';

let user: User = { language: 'de' };

if (typeof window !== 'undefined') {
  const token: string = localStorage.getItem('accessToken');
  const decodedToken = silentJwtDecode<{ user: User }>(token);

  if (decodedToken) {
    user = (decodedToken as { user: User }).user;
  }
}

const initialState: UserState = {
  ...user,
};

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case USER_UPDATE: {
      return {
        ...state,
        ...action.user,
      };
    }

    // case 'CHANGE_LANGUAGE': {
    //   return {
    //     ...state,
    //     language: action.language,
    //   };
    // }

    case LOGOUT: {
      return { language: state.language };
    }

    default: {
      return state;
    }
  }
};
