import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

type StoreObject = {
  [key: string]: string;
};

export default new InMemoryCache({
  dataIdFromObject: (object: StoreObject) => {
    const ids = Object.keys(object).filter((key) => key.match(/id/gi));

    if (ids.length > 1 || ids.length === 0)
      return defaultDataIdFromObject(object);

    return object[ids[0]];
  },
});
