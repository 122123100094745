import { h, FunctionComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { installMediaQueryWatcher } from 'pwa-helpers';

import Header from '../Header/Header';
import ErrorBar from '../ErrorBar/ErrorBar';
import Support from '../Support/Support';
import Footer from '../Footer/Footer';
import Routes from '../Routes/Routes';

import style from './style.module.css';

const App: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    installMediaQueryWatcher(`(max-width: 600px)`, (smallScreen) => {
      dispatch({
        type: 'CHANGE_SMALL_SCREEN',
        smallScreen,
      });
    });
  }, [dispatch]);

  return (
    <div class={style.wrapper}>
      <Header />
      <div class={style.body}>
        <div class={style.content}>
          <Routes />
        </div>
        <Support />
        <ErrorBar />

        <Footer />
      </div>
    </div>
  );
};

export default App;
