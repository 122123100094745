import {
  MainState,
  MainActionTypes,
  LOADING_START,
  LOADING_STOP,
  TOGGLE_DRAWER,
  CHANGE_SMALL_SCREEN,
  TOGGLE_VIEW_DELETED,
  OPEN_SUPPORT_PANEL,
  CLOSE_SUPPORT_PANEL,
  CLICK_DRAWER_ITEM,
} from './types';

const initialState: MainState = {
  loading: false,
  drawerOpen: false,
  smallScreen: false,
  viewDeleted: false,
  supportPanelOpen: false,
  nextPath: null,
  nextPathMessageCode: null,
};

export default (state = initialState, action: MainActionTypes): MainState => {
  switch (action.type) {
    case LOADING_START: {
      return { ...state, loading: true };
    }
    case LOADING_STOP: {
      return { ...state, loading: false };
    }

    case TOGGLE_DRAWER: {
      return { ...state, drawerOpen: !state.drawerOpen };
    }

    case CLICK_DRAWER_ITEM: {
      return {
        ...state,
        drawerOpen: !state.smallScreen,
      };
    }

    case CHANGE_SMALL_SCREEN: {
      return {
        ...state,
        smallScreen: action.smallScreen,
        drawerOpen: !action.smallScreen,
      };
    }

    case TOGGLE_VIEW_DELETED: {
      return { ...state, viewDeleted: !state.viewDeleted };
    }

    case OPEN_SUPPORT_PANEL: {
      return { ...state, supportPanelOpen: true };
    }

    case CLOSE_SUPPORT_PANEL: {
      return { ...state, supportPanelOpen: false };
    }

    case 'SET_NEXT_PATH': {
      return {
        ...state,
        nextPath: action.path,
        nextPathMessageCode: action.messageCode,
      };
    }

    case 'CLEAR_NEXT_PATH': {
      return { ...state, nextPath: null, nextPathMessageCode: null };
    }

    default: {
      return state;
    }
  }
};
