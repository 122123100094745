import { h, FunctionComponent } from 'preact';
import { useSelector } from 'react-redux';
import { Text, Localizer } from 'preact-i18n';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';

import style from './style.module.scss';
import { RootState } from '../../redux';

const LoginButton: FunctionComponent = () => {
  const userId = useSelector((state: RootState) => state.user.userId);

  return userId ? (
    <span>
      <Localizer>
        <Tooltip title={<Text id="shell.console" />}>
          <IconButton
            className={style.iconButton}
            href="/console"
            color="inherit"
            edge="end"
          >
            <Dashboard />
          </IconButton>
        </Tooltip>
      </Localizer>
      <Button className={style.consoleButton} href="/console" color="inherit">
        <Text id="shell.console" />
      </Button>
    </span>
  ) : (
    <div class={style.authButtons}>
      <Button href="/login" color="inherit">
        <Text id="shell.login" />
      </Button>
      <Button
        href="/signup"
        color="inherit"
        variant="outlined"
        className={style.signupButton}
      >
        <Text id="shell.signup" />
      </Button>
    </div>
  );
};

export default LoginButton;
