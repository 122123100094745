import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import { useText } from 'preact-i18n';
import { matchSorter } from 'match-sorter';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import quickActions, { Action } from './search';

import style from './style.module.scss';

const ConsoleSearch: FunctionComponent = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>(null);

  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
  const { noOptionFound, search }: { [key: string]: string } = useText({
    noOptionFound: 'components.ConsoleSearch.noOptionFound',
    search: 'components.ConsoleSearch.search',
  });
  /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */

  const smallScreen = useMediaQuery('(max-width: 350px)');
  if (smallScreen) return null;

  return (
    <div class={style.wrapper}>
      <Autocomplete
        id="search"
        autoComplete
        autoHighlight
        clearOnBlur
        clearOnEscape
        disableClearable
        forcePopupIcon={false}
        noOptionsText={noOptionFound}
        options={quickActions}
        filterOptions={(options: Action[], { inputValue: input }) =>
          matchSorter(options, input, { keys: ['title', 'url'] })
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'reset' || reason === 'clear') {
            setInputValue('');
            return;
          }
          setInputValue(newInputValue);
        }}
        onChange={(event, value: Action, reason) => {
          if (reason !== 'select-option') return;

          navigate(`/console/${value.url}`);
        }}
        getOptionLabel={(option: Action) => option.title}
        renderOption={(option: Action) => (
          <ListItem dense>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.title} />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            margin="dense"
            label={search}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              className: clsx(style.autoComplete, params.InputProps.className),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    </div>
  );
};

export default ConsoleSearch;
