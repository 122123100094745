import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from 'preact-i18n';
import { useNavigate } from 'react-router-dom';

import {
  ButtonBase,
  Menu,
  MenuItem,
  IconButtonProps,
  Divider,
} from '@material-ui/core';

import logout from '../../redux/actions/logout';
import { RootState } from '../../redux';

const SettingsButton: FunctionComponent<IconButtonProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userId);
  const { userInitial, email } = useSelector((state: RootState) => ({
    userInitial: (state.user.firstname || 'x')[0],
    email: state.user.email,
  }));

  const [anchorElement, setAnchorElement] = useState<Element>(null);

  return userId ? (
    <span>
      <ButtonBase
        focusRipple
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        className="rounded-full w-8 h-8 flex items-center justify-center border border-white bg-white text-black"
        onClick={(event) => setAnchorElement(event.currentTarget)}
        {...props}
      >
        <span>{userInitial}</span>
      </ButtonBase>

      <Menu
        id="long-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        <div class="inline-flex px-4 pt-2 pb-3 items-center align-center">
          <span class="rounded-full w-6 h-6 flex items-center justify-center border border-blue-400 bg-blue-400 text-white">
            <span>{userInitial}</span>
          </span>
          <span class="ml-2">{email}</span>
        </div>
        <Divider />
        <a href="/console/settings" tabIndex={-1}>
          <MenuItem onClick={() => setAnchorElement(null)}>
            <Text id="shell.settings" />
          </MenuItem>
        </a>
        <MenuItem
          onClick={() => {
            setAnchorElement(null);
            dispatch(logout());
            navigate('/');
          }}
        >
          <Text id="shell.logout" />
        </MenuItem>
      </Menu>
    </span>
  ) : null;
};

export default SettingsButton;
