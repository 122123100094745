import { h, FunctionComponent } from 'preact';
import { Text, MarkupText, Localizer } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  Fab,
  IconButton,
  Typography,
  Collapse,
  Slide,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import { Close, HelpOutline } from '@material-ui/icons';

import style from './style.module.css';
import { RootState } from '../../redux';

const Support: FunctionComponent = () => {
  const open = useSelector((state: RootState) => state.main.supportPanelOpen);
  const dispatch = useDispatch();

  return (
    <span>
      <Collapse in={!open}>
        <Localizer>
          <Tooltip title={<Text id="components.Support.tooltip" />}>
            <Fab
              color="secondary"
              className={style.element}
              onClick={() => dispatch({ type: 'OPEN_SUPPORT_PANEL' })}
            >
              <HelpOutline />
            </Fab>
          </Tooltip>
        </Localizer>
      </Collapse>
      <Slide direction="up" in={open}>
        <Card className={clsx(style.element, style.card)}>
          <Localizer>
            <CardHeader
              title={<Text id="components.Support.heading" />}
              action={
                <IconButton
                  onClick={() => dispatch({ type: 'CLOSE_SUPPORT_PANEL' })}
                >
                  <Close />
                </IconButton>
              }
            />
          </Localizer>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <MarkupText
                id="components.Support.body"
                fields={{ email: 'support@wasc.io' }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Slide>
    </span>
  );
};

export default Support;
