import { Observable, ApolloLink, Operation } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { dispatch } from '../../redux';

import graphqlRequest from '../graphqlRequest';
import logout from '../../redux/actions/logout';

const query = `mutation ($refreshToken: String!, $clientId: String!) {
  refreshTokens(input: { refreshToken: $refreshToken, client: { clientId: $clientId } }) {
    tokens {
      accessToken
    }
  }
}`;

type RefreshRequestResponse = {
  refreshTokens: {
    tokens: {
      accessToken: string;
    };
  };
};

async function request(operation: Operation) {
  if (typeof window === 'undefined') return;

  const expiration = parseInt(localStorage.getItem('tokenExpires'), 10);

  if (!expiration) return;

  const now = Date.now() / 1000;

  let token = '';

  // The token will expire within the next 5 seconds
  if (expiration - now < 5) {
    const { data } = await graphqlRequest<RefreshRequestResponse, unknown>({
      query,
      variables: {
        clientId: CLIENT_ID,
        refreshToken: window.localStorage.getItem('refreshToken'),
      },
    });

    if (!data) {
      dispatch({
        type: 'SET_NEXT_PATH',
        path: '/current-path',
        messageCode: 'views.login.loginToProceed',
      });
      dispatch(logout());

      window.history.pushState({}, '', '/login');

      return;
    }

    // Set the expiration for the new token
    const { exp } = jwtDecode<{ exp: number }>(
      data.refreshTokens.tokens.accessToken,
    );
    window.localStorage.setItem('tokenExpires', exp.toString());
    window.localStorage.setItem(
      'accessToken',
      data.refreshTokens.tokens.accessToken,
    );

    token = data.refreshTokens.tokens.accessToken;
  }

  if (!token) token = window.localStorage.getItem('accessToken');

  operation.setContext({
    headers: {
      authorization: token,
    },
  });
}

export default new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: ZenObservable.Subscription;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          /* eslint-disable @typescript-eslint/no-unsafe-assignment */
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
          /* eslint-enable @typescript-eslint/no-unsafe-assignment */
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);
