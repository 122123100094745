export interface User {
  language: string;
  email?: string;
  userId?: string;
  firstname?: string;
  lastname?: string;
  admin?: string[];
}

export type UserState = User;

export const USER_UPDATE = 'USER_UPDATE';
export const LOGOUT = 'LOGOUT';

interface UserUpdateAction {
  type: typeof USER_UPDATE;
  user: User;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type UserActionTypes = UserUpdateAction | LogoutAction;
