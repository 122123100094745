import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';

import { Text } from 'preact-i18n';

import { Button, Popover } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import style from './style.module.css';

const Links: FunctionComponent = () => (
  <span>
    <Button className={style.navButton} href="/why" color="inherit">
      <Text id="shell.headings.why" />
    </Button>
    <Button className={style.navButton} href="/products" color="inherit">
      <Text id="shell.headings.products" />
    </Button>
    <Button className={style.navButton} href="/documentation" color="inherit">
      <Text id="shell.headings.documentation" />
    </Button>
  </span>
);

const Navigation: FunctionComponent = () => {
  const [anchorElement, setAnchorElement] = useState<
    EventTarget & HTMLButtonElement
  >(null);

  return (
    <div class="flex">
      <Button
        onClick={(event) => setAnchorElement(event.currentTarget)}
        className={style.popButton}
        color="inherit"
      >
        <Text id="shell.header.more" />
        <KeyboardArrowDownIcon />
      </Button>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClick={() => setAnchorElement(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Links />
      </Popover>
      <div class={style.fullNavigation}>
        <Links />
      </div>
    </div>
  );
};

export default Navigation;
