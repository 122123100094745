import { h, FunctionComponent } from 'preact';
import { Suspense, lazy } from 'preact/compat';

const RouteContent = lazy(() => import('./RouteContent'));

const RouterWrapper: FunctionComponent = () => {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <RouteContent />
    </Suspense>
  );
};

export default RouterWrapper;
