import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    common: { black: 'rgba(55, 71, 79, 1)', white: 'rgba(250, 250, 250, 1)' },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(250, 250, 250, 1)',
    },
    primary: {
      light: '#7986cb',
      main: 'rgba(66, 164, 245, 1)',
      dark: '#303f9f',
      contrastText: 'rgba(245, 245, 245, 1)',
    },
    secondary: {
      light: '#ff4081',
      main: 'rgba(66, 164, 245, 1)',
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(74, 89, 96, 1)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});
