import { h, FunctionComponent } from 'preact';
import { IntlProvider, useText } from 'preact-i18n'; // eslint-disable
import { Helmet } from 'react-helmet';
import App from './component';

import locales from '../../locales';

const AppComponent: FunctionComponent = () => {
  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
  const {
    title,
    description,
  }: {
    [key: string]: string;
  } = useText({
    title: 'meta.title',
    description: 'meta.description',
  });
  /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */

  return (
    <div>
      <IntlProvider definition={locales} scope="de">
        <Helmet>
          <html lang="de" />
          <meta charSet="utf-8" />

          {/* <!-- Primary Meta Tags --> */}
          {/* Title needs to hardcoded */}
          <title>Wasc Cloud - Privacy built-in</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Wasc Cloud" />
          <meta property="og:url" content="https://wasc.io/" />
          <meta property="og:title" content={title} />

          <meta property="og:description" content={description} />
          {/* <meta property="og:image" content="" /> */}

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://wasc.io/" />

          <meta property="twitter:title" content={title} />

          <meta property="twitter:description" content={description} />

          {/* <meta property="twitter:image" content="" /> */}
        </Helmet>
        <App />
      </IntlProvider>
    </div>
  );
};
export default AppComponent;
