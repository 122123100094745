import { LogoutAction } from '../store/user/types';

import setCookie from '../../lib/setCookie';

/**
 * Log the user out
 */
export default (): LogoutAction => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('tokenExpires');

    // Remove cookies
    setCookie('access_token', '');
    setCookie('refresh_token', '');
  }

  return {
    type: 'LOGOUT',
  };
};
