import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import mainReducer from './store/main/reducer';
import userReducer from './store/user/reducer';
import errorReducer from './store/error/reducer';
import { MainState } from './store/main/types';
import { UserState } from './store/user/types';
import { ErrorState } from './store/error/types';

const reducers = combineReducers({
  main: mainReducer,
  user: userReducer,
  error: errorReducer,
});

const composedEnhancers = composeWithDevTools(applyMiddleware(thunk));

const store = createStore(reducers, composedEnhancers);

export default store;
export const { dispatch } = store;

export interface RootState {
  main: MainState;
  user: UserState;
  error: ErrorState;
}
