export interface Error {
  userId?: string;
  language: string;
  extensions: {
    code: string;
  };
}

export interface ErrorState {
  errors: Error[];
}

export const ENQUEUE_ERROR = 'ENQUEUE_ERROR';
export const DEQUEUE_ERROR = 'DEQUEUE_ERROR';

interface EnqueueErrorAction {
  type: typeof ENQUEUE_ERROR;
  errors: Error[];
}

interface DequeueErrorAction {
  type: typeof DEQUEUE_ERROR;
}

export type ErrorActionTypes = EnqueueErrorAction | DequeueErrorAction;
