import { h, FunctionComponent } from 'preact';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from 'preact-i18n';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { RootState } from '../../redux';

const ErrorBar: FunctionComponent = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.error.errors);

  if (!errors.length) return null;

  const additionalErrorCount = errors.length - 1;
  const currentError = errors[0];

  return (
    <Snackbar
      open
      key={errors.length}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        dispatch({ type: 'DEQUEUE_ERROR' });
      }}
    >
      <Alert variant="filled" severity="error">
        <Text id={`errors.${currentError.extensions.code}`}>
          An error occured
        </Text>
        {errors.length > 1 && (
          <span>
            (
            <Text
              id="components.ErrorBar.moreErrors"
              fields={{ additionalErrorCount }}
              plural={additionalErrorCount}
            />
            )
          </span>
        )}
      </Alert>
    </Snackbar>
  );
};

export default ErrorBar;
