// Must be the first import
if (process.env.NODE_ENV !== 'production') {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
  require('preact/debug'); // eslint-disable-line global-require
}

/* eslint-disable import/first */
import { h, FunctionComponent, render } from 'preact';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { Provider as Redux } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

import ApolloClient from './lib/apollo/client';

import MatomoInstance from './lib/matomo/instance';

import App from './components/App/App';

import store from './redux';
import './info';

import theme from './style/theme';

// import 'typeface-roboto';
// import 'typeface-aleo';
import 'fontsource-clear-sans';
import './style/index.module.css';

/* eslint-enable import/first */

const Root: FunctionComponent = () => {
  return (
    <ApolloProvider client={ApolloClient}>
      <Redux store={store}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MatomoProvider value={MatomoInstance}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </MatomoProvider>
          </ThemeProvider>
        </StylesProvider>
      </Redux>
    </ApolloProvider>
  );
};

render(h(Root, {}), document.querySelector('.root')); // eslint-disable-line no-undef
