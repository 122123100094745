import views from './views';

export default {
  meta: {
    title: 'Wasc Cloud - Privacy built-in',
    description:
      'Public Cloud, mit Datenschutz als höchstem Anliegen. Wasc wurde mit dem Ziel gegründet, Ihre Daten zu schützen.',
  },
  shell: {
    // UserArea
    console: 'Console',
    login: 'Login',
    signup: 'Registrieren',
    settings: 'Einstellungen',
    logout: 'Logout',
    header: {
      more: 'Mehr',
    },

    headings: {
      why: 'Warum Wasc',
      home: 'Home',
      products: 'Produkte',
      documentation: 'Dokumentation',
    },

    footer: {
      press: 'Presse',
      contactAndSupport: 'Kontakt & Support',

      why: 'Warum Wasc',
      imprintAndPrivacy: 'Impressum & Datenschutz',
      terms: 'Allgemeine Geschäftsbedingungen (AGB)',
    },

    // Toasts
    newVersion: 'Neue Version verfügbar',
    refresh: 'Neuladen',
  },
  global: {
    image: {
      UBUNTU_1810: 'Ubuntu 18.10',
      UBUNTU_1804: 'Ubuntu 18.04',
      UBUNTU_1710: 'Ubuntu 17.10',
    },

    costPerDay: 'Preis pro Tag: {{amount}} €',

    unit: {
      gb: {
        one: 'GB',
        many: 'GBs',
      },
      core: {
        one: 'Core',
        many: 'Cores',
      },
    },
  },

  components: {
    NotFound: 'Die Route wurde nicht gefunden. Das ist alles, was wir wissen',
    ConsoleSearch: {
      noOptionFound: 'Keine Seite gefunden.',
      search: 'Gehe zu',
    },
    PortAudits: {
      openPorts: 'Offene Ports',
      noAudits:
        'Bislang gab es noch keinen Audit. Bitte warten Sie, bis das nächste Mal gescannt wurde.',
    },
    Support: {
      tooltip: 'Hilfe',
      heading: 'Support',
      body:
        'Wie können wir Ihnen helfen? Schreiben Sie uns eine Email an: <a href="mailto:support@wasc.io">support@wasc.io</a>.<br />Oder schreiben Sie uns auf <a href="tel:+49 15678 345787">Signal (+49 15678 345787)</a> oder <a href="https://wa.me/message/IXBVXGYQ6KKXA1">WhatsApp</a>',
    },
    server: {
      attributes: {
        core: { singular: '{{core}} Core', plural: '{{core}} Cores' },
        ram: '{{ram}} GB RAM',
        name: 'Name des Servers',

        newService: 'Neue Services für den Server',
        newVolume: 'Neue Volumes für den Server',

        disk: 'Speicher',
        image: 'Betriebssystem',

        ip: {
          ip: 'IP',
          name: 'Name der IP',
          ports: {
            from: 'von Port',
            to: 'nach Port',
            protocol: 'L4 Protokoll',
            name: 'Name des Ports',
          },
        },
      },
    },
    SshKeys: {
      heading: 'SSH-Keys',
      description:
        'Verwalten Sie hier Ihre SSH-Keys. Unten sehen Sie alle Schlüssel, die einem Server hinzugefügt werden können und Durch einen Klick auf den Knopf können Sie einen schon vorhandenen SSH-Schlüssel hochladen.',
      noKeys: 'Sie haben noch keine Schlüssel hinterlegt.',
      add: 'SSH-Key hinzufügen',
      pasteOr: 'Schlüssel aus Zwischenablage einfügen oder ',
      uploadFromFile: 'aus Datei hochladen',
      publicSshKey: 'Öffentlicher SSH-Schlüssel',
      keyName: 'Schlüsselname',
      saveKey: 'Schlüssel speichern',
      cancel: 'Abbrechen',
    },
    ServerSshKeyInput: {
      noKeys: 'Sie haben noch keine Schlüssel hinterlegt.',
      manageKeys: 'Ihre Schlüssel verwalten',
    },
    ServerPasswordInput: 'Passwort zum Hashen eingeben',
    deleteButton: 'Zum endgültigen Löschen erneut klicken',
    deletePaperButton: {
      delete: 'Löschen',
      deleteForever: 'Endgültig Löschen',
    },
    SubscribeToNewsletter: {
      emailInput: 'Email-Adresse',
      subscribeButton: 'Abonnieren',
    },
    ErrorBar: {
      moreErrors: {
        one: 'Und ein weiterer Fehler',
        many: 'Und {{additionalErrorCount}} weitere Fehler',
      },
    },
    ServerList: {
      deleted: 'Server gelöscht',
    },
  },
  views,

  errors: {
    BIC_INVALID: 'Die BIC is ungültig.',
    CLIENT_DISABLED: 'Der OAuth-Client ist deaktiviert.',
    CLIENT_NOT_FOUND: 'Der OAuth-Client wurde nicht gefunden.',
    EMAIL_EXISTS: 'Die Email existiert bereits.',
    IBAN_INVALID: 'Die IBAN ist ungültig',
    IMAGE_NOT_FOUND: 'Das Image wurde nicht gefunden',
    INTERNAL_ERROR:
      'Ein interner Fehler ist aufgetreten, bitte Support kontaktieren',
    INTERNAL_SERVER_ERROR:
      'Ein interner Fehler ist aufgetreten, bitte Support kontaktieren',
    NO_EMAIL_PROVIDED: 'Kein Email wurde angegeben.',
    NO_INTERNAL_API_KEY: 'Kein interner API-Schlüssel wurde angegeben.',
    NO_LANGUAGE_PROVIDED: 'Keine Sprache angegeben.',
    NO_SERVER_PROVIDED: 'Kein Server angegeben.',
    NO_VALID_MANDATE: 'Kein gültiges Lastschriftmandat, bzw. Zahlungsdetails.',
    PASSWORDS_IDENTICAL: 'Die Passwörter sind identisch.',
    PASSWORD_INCORRECT: 'Das Passwort ist falsch.',
    REFRESH_TOKEN_EXPIRED: 'Das refreshToken ist abgelaufen.',
    REFRESH_TOKEN_INVALID: 'Das refreshToken ist ungültig.',
    SERVER_DELETED: 'Der Server wurde bereits gelöscht.',
    SERVER_HAS_NO_SERVICE: 'Der Server hat keinen Service.',
    SERVER_HAS_NO_VOLUME: 'Der Server hat kein Volume.',
    SERVER_NOT_FOUND: 'Der Server wurde nicht gefunden.',
    SERVER_NOT_PAID: 'Der Server wurde noch nicht bezahlt.',
    SERVICE_DELETED: 'Der Service wurde bereits gelöscht.',
    SERVICE_NOT_FOUND: 'Der Service wurde nicht gefunden.',
    USER_EXISTS: 'Der User existiert bereits.',
    USER_NOT_FOUND: 'Der User wurde nicht gefunden.',
    VALIDATION_ERROR: 'Daten im falschen Format angegeben.',
    VOLUME_ATTACH_NOT_SUPPORTED:
      'Das Anbinden des Volumes wird nicht unterstützt.',
    VOLUME_DELETED: 'Das Volume wurde bereits gelöscht.',
    VOLUME_NOT_FOUND: 'Das Volume wurde nicht gefunden.',
    VOLUME_NOT_PROVISIONED: 'Einige Volumes sind noch nicht bereit.',
    EMAIL_NOT_FOUND: 'Die Email-Adresse wurde nicht gefunden.',
    TOKEN_ALREADY_REGISTERED:
      'Das Sicherheitstoken wurde dem Konto bereits hinzugefügt',
  },
};
