import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import graphqlMiddleware from './graphqlMiddleware';
import localCache from './cache';
import { dispatch } from '../../redux/index';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, // eslint-disable-line @typescript-eslint/restrict-template-expressions
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line @typescript-eslint/restrict-template-expressions

  dispatch({
    type: 'ENQUEUE_ERROR',
    errors: graphQLErrors,
  });
});

const httpLink = new HttpLink({
  uri: WASC_APIS,
});

const link = ApolloLink.from([
  errorLink,
  graphqlMiddleware,
  httpLink,
] as ApolloLink[]);

export default new ApolloClient({
  link,
  cache: localCache,
  defaultOptions: {
    watchQuery: {
      partialRefetch: true,
    },
  },
});
