import { h, FunctionComponent } from 'preact';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';

import { Menu, ChevronLeft, Search } from '@material-ui/icons';

import Navigation from './Navigation/Navigation';
import ConsoleSearch from '../ConsoleSearch/ConsoleSearch';

import LoginButton from '../LoginButton/LoginButton';
import OptionsMenu from '../OptionsMenu/OptionsMenu';

import { TOGGLE_DRAWER } from '../../redux/store/main/types';

import style from './style.module.scss';
import { RootState } from '../../redux';

const Header: FunctionComponent = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state: RootState) => state.main.loading);
  const open = useSelector((state: RootState) => state.main.drawerOpen);

  const consoleMatch = useMatch('/console*');
  const docsMatch = useMatch('/documentation*');

  const smallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <AppBar position="sticky">
      <Toolbar className={style.topbar}>
        {smallScreen && Boolean(consoleMatch || docsMatch) ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => dispatch({ type: TOGGLE_DRAWER })}
          >
            {open ? <ChevronLeft /> : <Menu />}
          </IconButton>
        ) : (
          <div />
        )}

        <a href="/" class="inline-flex justify-between">
          <img
            src="../../assets/logos/logo_white-noshadow-nofont.svg"
            class="h-8"
            alt=""
          />
          <div class="sm:block hidden ml-2 font-semibold self-center text-white">
            Wasc&nbsp;Cloud
          </div>
        </a>

        {consoleMatch !== null ? <ConsoleSearch /> : <Navigation />}

        {loading ? <CircularProgress className="text-white" /> : <div />}
        <LoginButton />

        <OptionsMenu color="inherit" edge="end" />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
