import { h, FunctionComponent } from 'preact';
import { Text } from 'preact-i18n';
import { useDispatch } from 'react-redux';

import { Button, IconButton, Container, Tooltip } from '@material-ui/core';
import { GitHub, LinkedIn, Twitter } from '@material-ui/icons';

import style from './style.module.css';

const Footer: FunctionComponent = () => {
  const dispatch = useDispatch();
  return (
    <footer class={style.content}>
      <div class={style.top}>
        <Container>
          <div class={style.topWrapper}>
            <div>
              <Tooltip title="GitHub" placement="top">
                <IconButton
                  href="https://github.com/wasc-io"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <GitHub />
                </IconButton>
              </Tooltip>

              <Tooltip title="LinkedIn" placement="top">
                <IconButton
                  href="https://www.linkedin.com/company/12976758"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <LinkedIn />
                </IconButton>
              </Tooltip>

              <Tooltip title="Twitter" placement="top">
                <IconButton
                  href="https://twitter.com/wasc_io"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <Twitter />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <div>
                <Button href="/press" color="inherit">
                  <Text id="shell.footer.press" />
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => dispatch({ type: 'OPEN_SUPPORT_PANEL' })}
                >
                  <Text id="shell.footer.contactAndSupport" />
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div class={style.bottom}>
        <div>
          <a href="/why">
            <Text id="shell.footer.why" />
          </a>{' '}
          |{' '}
          <a href="/imprint-privacy">
            <Text id="shell.footer.imprintAndPrivacy" />
          </a>{' '}
          |{' '}
          <a href="/terms">
            <Text id="shell.footer.terms" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
