/* eslint-disable global-require */
import dataProtection from './privacy/main.md';
import products from './products/main.md';
/* eslint-enable global-require */

export default {
  home: {
    slogan: 'Die datenschutz-priorisierte öffentliche Cloud. Wasc Cloud.',
    sloganSubtext: `Wir wollen den Beginn des datenschutz-priorisierten Internets beschleunigen, indem wir für Unternehmen überzeugende Cloud-Produkte mit integriertem Datenschutz zur Verfügung stellen.`,
    action:
      'Informiereren Sie sich über unser Produkt, ein dynamisch skalierbarer, GraphQL-API erreichbarer und stabiler virtueller Server.',
    button: 'Wasc Opus',
    newsletter:
      'Halten Sie sich auf dem Laufenden, in dem Sie unseren Newsletter abonnieren:',
  },

  data_protection: {
    content: dataProtection.html,
  },

  products: {
    content: products.html,
  },

  cloud: {
    sloganA: 'Your data.',
    sloganB: 'safely stored',
    subtext:
      'We want your data to be stored with piece of mind, free of spying, encrypted by user-choice and securely replicated',
    actionButton: 'Get Started',
    documentationButton: 'Learn more',
  },

  login: {
    heading: 'Login',

    loginToProceed: 'Bitte einloggen, um fortzufahren.',
    email: 'Email',
    password: 'Passwort',
    loginButton: 'Login',
    nextButton: 'Weiter',
    tokenButton: 'Token bestätigen',

    enterEmail: 'Bitte geben Sie die Email-Adresse für Ihr Wasc-Konto ein.',
    enterPassword: 'Bitte geben Sie das Passwort zu Ihrem Wasc-Konto ein.',
    enterToken: 'Bitte bestätigen Sie Ihr Sicherheits-Token.',
    waitVerify: 'Bitte warten Sie, während Ihre Zugangsdaten überprüft werden.',

    userNotFound: 'Es wurde unter der Email kein Benutzer gefunden.',
    passwordIncorrect: 'Das Passwort ist falsch.',
    internalError: 'Ein Fehler ist aufgetreten, bitte erneut versuchen',
    supportError:
      'Ihr Browser unterstützt keine 2-Faktor-Authentifizierung, was in Ihrem Konto aktiviert ist. Sie müssen Ihren Browser updaten, um sich einloggen zu können.',

    signup: 'Registrieren',
  },

  signup: {
    heading: 'Registrieren',

    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    password: 'Passwort',
    confirmPassword: 'Passwort bestätigen',

    required: 'Bitte ausfüllen.',
    emailRequired: 'Bitte eine gültige Email-Adresse eingeben.',
    passwordIncorrect: 'Bitte ein sicheres Passwort benutzen.',
    passwordConfirmIncorrect: 'Bitte das Passwort richtig bestätigen.',

    terms:
      'Ich akzeptiere die <a href="/terms" target="_blank" rel="noopener noreferrer" >allgemeinen Geschäftsbedingungen (AGB)</a> von Wasc',

    signupButton: 'Registrieren',
  },

  settings: {
    heading: 'Einstellungen',
    password: {
      heading: 'Passwort ändern',
      explanation:
        'Das Ändern das Passwortes kann bis zu sieben Sekunden dauern, da wir durch gründliches Hashen verhindern, dass im Falle eines Leaks die Passwörter gebruteforced werden können.',

      currentPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      confirmPassword: 'Bestätigungspasswort',

      currentPasswordError: 'Bitte das richtige Passwort eingeben.',
      newPasswordError: 'Bitte ein neues Passwort eingeben.',
      confirmPasswordError: 'Bitte das Passwort richtig bestätigen.',

      changeButton: 'Ändern',
    },
    mandate: {
      heading: 'Kontodaten',
      explanation:
        'Die IBAN wird dazu benutzt um die von Ihnen verbrauchten Resourcen per Lastschriftverfahren von Ihrem Konto einzuziehen.',

      currentMandate:
        'Die nächste Rechnung wird über die IBAN {{iban}} abgewickelt.',

      mandate: 'IBAN',
      mandateError: 'Die IBAN ist fehlerhaft',

      changeButton: 'Ändern',
    },
    email: {
      heading: 'Email-Adresse ändern',
      explanation:
        'Die Änderung der Email-Adresse ändert sowohl die Loginadresse, als auch die standard Benachrichtigungsadresse.',

      currentEmail: 'Aktuelle Email-Adresse',
      newEmail: 'Neue Email-Adresse',

      changeButton: 'Ändern',
    },
    securityToken: {
      heading: 'Sicherheits-Token',
      explanation:
        'Mit Sicherheitstokens können Sie die Zugangssicherheit Ihres Account verstärken. Bei der Verwedung eines Sicherheits-Tokens wird Ihr Konto über kryptografische Verfahren vor einer Übernahme geschützt. Phishingangriffe sind so nicht mehr möglich.',
      yourTokens: 'Ihre registrierten Sicherheitstokens:',
      noTokens: 'Sie haben noch keine Schlüssel registriert.',

      newTokenName: 'Name des neuen Tokens',

      saveToken: 'Token speichern',
      newTokenButton: 'Neues Token registrieren',
    },
  },

  console: {
    shell: {
      main: 'Home',
      servers: 'Server',
      finances: 'Finanzen',
      security: 'Sicherheit',
      order: 'Bestellen',
      developers: 'Entwickler',
    },

    main: {
      servers: 'Servers',
      noServers: 'Noch kein Server erstellt.',

      finances: 'Finanzen',
      invoices: 'Rechnungen',
      noInvoices: 'Es gibt noch keine Rechnungen.',

      notifications: 'Benachrichtigungen',
      noNotifcations: 'Bislang noch keine Benachrichtigungen.',

      quickActions: 'Quick-Actions',

      logout: 'Logout',
      settings: 'Einstellungen',
      order: 'Bestellen',
    },

    server: {
      heading: 'Server',
      viewDeleted: 'Gelöschte Server anzeigen',
      noServers:
        'Sie haben noch keine Server erstellt. <a href="/console/order">Hier einen Server bestellen</a>.',
      lastAction: 'Letztes Event: {{lastAction}}',
      serverId: 'ServerId',
      attributes: 'Eigenschaften',
      history: 'Events',
      deleteConfirmation:
        'Sind Sie sicher, dass Sie den Server dauerhaft löschen wollen?',
      abort: 'Abbrechen',
      upgradeRebootNotice:
        'Der Server wurde geupgraded. Ein Neustart ist notwending, damit die Änderungen wirksam werden.',
      actions: {
        START: 'Starten',
        STOP: 'Herunterfahren',
        UPGRADE: 'Upgrade',
        DELETE: 'Löschen',
      },
    },
    servers: {
      status: {
        START: 'Der Server wurde hochgefahren.',
        AWAIT_START: 'Der Start wurde beantragt.',
        STOP: 'Der Server wurde zuletzt heruntergefahren.',
        REBOOT: 'Der Server wurde zuletzt neugestartet.',
        UPGRADE: 'Der Server wurde zuletzt geupgraded.',
        DELETE:
          'Der Server zuletzt wurde gelöscht. All further changes are disabled.',
        CREATE: 'Der Server zuletzt wurde erstellt.',
        AWAIT_CREATION: 'Der Server wartet auf die Bezahlung.',
      },
    },

    security: {
      heading: 'Sicherheit',
    },

    change: {
      heading: 'Den Server verändern',
      changeAction: 'Ändern',
    },

    finances: {
      invoices: {
        heading: 'Rechnungen',
        noInvoices: 'Bislang haben wir Ihnen noch nichts in Rechnung gestellt.',
        item: {
          headingClosed: 'Rechnung vom {{date}} über {{amount}} €',
          headingClosedShort: '{{date}}, {{amount}} €',
          headingOpened: 'Rechnung vom {{date}}',

          itemType: {
            SERVER: 'Wasc Opus',
            VOLUME: 'Volume',
            SERVICE: 'Service',
          },

          itemDescriptionHeading: 'Benutzungstypen',
          itemUsageHeading: 'ungefähre Benutzung',
          itemCostHeading: 'Kosten',

          subItemType: {
            RUNNING: 'laufend',
            STANDBY: 'standby',
            IP: 'Public static IP',
          },

          subItemUnit: {
            IP: '{{amount}} Stück',
          },

          costAmount: '{{amount}} €',

          netInvoiceCost: 'Netto Gesamtkosten: {{amount}} €',
          grossInvoiceCost: 'Brutto Gesamtkosten: {{amount}} €',

          exportToPdf: 'Als PDF exportieren',
          paidWith: 'Lastschrift vom Konto mit der IBAN {{iban}} eingezogen.',
        },
      },
    },

    order: {
      heading: 'Bestellen',
      noMandate:
        'Sie haben leider bislang keine gültigen Kontodaten hinterlegt. Deswegen können Sie nichts bestellen. <a href="/console/settings">Legen Sie hier ein neues Einzugsmandat an.</a>',

      steps: {
        hardware: {
          heading: 'Hardware',
          description:
            'Die Komponenten CPU, RAM, Volumes und Services auswählen.',
        },
        naming: {
          heading: 'Name',
          description: 'Bitte dem Server einen Namen vergeben.',
        },
        security: {
          heading: 'Sicherheit',
          description:
            'Um auf den Server über den Standardnutzer `wasc` zugreifen zu können, müssen Sie einen oder mehrere SSH-Schlüssel hinterlegen.',
        },
        finalize: {
          heading: 'Abschluss',
          description:
            'Bitte die Bestellung auf dem rechten Panel abschließen.',
        },
      },

      panel: {
        back: 'Zurück',
        next: 'Weiter',
        order: 'Zahlungspflichtig Bestellen',

        legal: {
          // Needs to be a complete sentence
          accept: 'Bitte akzeptieren Sie den',
          contract: 'Kaufvertrag',
          and: 'und die',
          terms: 'allgemeinen Geschäftsbedingungen (AGB).',
        },
        errors: {
          noVolumes: 'Der Server hat keine Volumes.',
          noSsh: 'Es wurde keine SSH-Schlüssel hinterlegt.',
          noServices: 'Der Server hat keine Services.',
        },
      },
    },
    developers: {
      heading: 'Entwickler',
      tokens: {
        heading: 'Tokens',
        currentTokensHeading: 'Ihre bisherigen Tokens:',
        newTokenHeading: 'Neues Token',
        newTokenExplanation:
          'Um Zugriff auf die API zu bekommen, müssen Sie hier ein RefreshToken erstellen. <a href="/documentation/api/authentication">Mehr Infos zur Authentifizierung in der Dokumentation.</a>',
        tokenNameInput: 'Verwendung des Tokens',
        nameInvalid: 'Bitte einen Namen eingeben',
        passwordInput: 'Zum Erstellen bitte das Passwort eingeben',
        passwordInvalid: 'Bitte das richtige Passwort eingeben',
        scopeExplanation:
          'Auf welche Bereiche soll das Token Zugriff haben. <a href="/documentation/api/scopes">Weitere Informationen über Scopes in der Dokumentation</a>',
        createTokenButton: 'Erstellen',

        scopeError: {
          SelectScope:
            'Bitte ein Scope auswählen, da das Token ohne Scope keine Berechtigung hätte.',
        },

        newToken: 'Das neue RefreshToken: <i>{{token}}</i>',
        newTokenNote:
          'Bitte kopieren Sie das Token, da Sie es nie wieder abrufen können.',
      },
    },
  },
  authorize: {
    heading: '<i>{{name}}</i> möchte auf Dein Konto zugreifen',
    noScopes:
      'Die App möchte nur den User identifizieren und braucht keine weiteren Rechte.',
    description: 'Sie fragt nach folgenden Berechtigungen:',
    authorize: 'Authorisieren',
    deny: 'Ablehnen',
  },
  webauthnTest: {
    supported: 'Ihr Browser unterstützt FIDO2/Webauthn',
    notSupported: 'Ihr Browser unterstützt kein FIDO2/Webauthn',
  },

  contact: {
    beta: 'Hallo Nicolai, hallo Dariush, ich würde gerne Beta-Tester werden!',
  },
};
