import { h } from 'preact';

import { CreditCard, Security, Code, Settings } from '@material-ui/icons';

export type Action = {
  title: string;
  url: string;
  icon: preact.JSX.Element;
};

export default [
  { title: 'Finances', url: '/finances', icon: <CreditCard /> },
  { title: 'Developers', url: '/developers', icon: <Code /> },
  { title: 'Change Password', url: '/settings', icon: <Settings /> },
  { title: 'Change Email', url: '/settings', icon: <Settings /> },
  { title: 'Change Bankdata', url: '/settings', icon: <Settings /> },
  { title: 'Change Security-Tokens', url: '/settings', icon: <Settings /> },
];
